* {
  box-sizing: border-box;
}

body {
  background: #F5F5F5;
  height: 100vh;
  width: 100vw; 
}

.container {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  grid-template-rows: 1fr;
  height: 100vh;
  width: 100vw; 
}

.container > img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.links {
  display: flex;
  gap: 40px;
}

.mobile {
  display: none;
}

.links a {
  font-family: "Inter", sans-serif;
  text-decoration: none;
  font-weight: 500;
}

.content {
  padding: 100px 80px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 66px;
  max-width: 640px;
}

.content p {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  line-height: 30px;
  color: #333;
}

.companies {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.companies img {
  max-width: 135px;
  max-height: 35px;
  object-fit: contain;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

@media (max-width: 768px) {
  .container {
    grid-template-columns: 1fr;
    grid-template-rows: 120px 1fr 1fr;
    /* padding: 36px; */
  }
  
  .container > img {
    height: 320px;
    padding: 0 36px;
  }

  .content {
    width: 100%;
    max-width: none;
    padding: 0px 36px;
    gap: 20px;
  }

  .links {
    display: none;
    width: 100%;
  }

  .links.mobile {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .links.mobile a {
    font-size: 20px;
    font-weight: 500;
    /* padding-bottom: 36px; */
  }

  .companies {
    display:flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
    gap: 20px;
  }
}